import { Document, Font, Image, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';
import React from 'react';
import { ProductDataResult } from '../../Models/ProductData';

Font.register({ family: 'Univia-pro,sans-serif', src: "https://use.typekit.net/qqu8qhw.css" });
Font.register({ family: 'Rajdhani', src: "/Assets/Rajdhani" });
Font.register({ family: 'Rajdhani-Light', src: "/Assets/Rajdhani/Rajdhani-Light.ttf" });
Font.register({ family: 'Rajdhani-Regular', src: "/Assets/Rajdhani/Rajdhani-Regular.ttf" });
Font.register({ family: 'Rajdhani-Bold', src: "/Assets/Rajdhani/Rajdhani-Bold.ttf" });
Font.register({ family: 'Rajdhani-SemiBold', src: "/Assets/Rajdhani/Rajdhani-SemiBold.ttf" });
Font.register({ family: 'Rajdhani-Medium', src: "/Assets/Rajdhani/Rajdhani-Medium.ttf" });
Font.register({ family: 'Roboto', src: "/Assets/Roboto" });
Font.register({ family: 'Roboto-Regular', src: "/Assets/Roboto/Roboto-Regular.ttf" });
Font.register({ family: 'Roboto-Black', src: "/Assets/Roboto/Roboto-Black.ttf" });
Font.register({ family: 'Roboto-Medium', src: "/Assets/Roboto/Roboto-Medium.ttf" });
Font.register({ family: 'Roboto-Light', src: "/Assets/Roboto/Roboto-Light.ttf" });
Font.register({ family: 'Roboto-LightItalic', src: "/Assets/Roboto/Roboto-LightItalic.ttf" });


type ModalExportState = {
    show: boolean,
    generatedBom: Array<ProductDataResult>,
    recommendedProducts: Array<ProductDataResult>,
    additionalProducts: Array<ProductDataResult>,
    isDivProjectDetailsVisible: boolean,
    isDivConfirmationVisible: boolean,
    isRefreshed: string,
    fileName: string
}

type ModalExportProps = {
    generatedBom: Array<ProductDataResult>,
    recommendedProducts: Array<ProductDataResult>,
    additionalProducts: Array<ProductDataResult>
}

class DownloadPDFButton extends React.Component<ModalExportProps, ModalExportState>{

    state: ModalExportState = {
        show: false,
        generatedBom: new Array<ProductDataResult>(),
        recommendedProducts: new Array<ProductDataResult>(),
        additionalProducts: new Array<ProductDataResult>(),
        isDivProjectDetailsVisible: true,
        isDivConfirmationVisible: false,
        isRefreshed: "",
        fileName: "",
    };

    constructor(props: any) {
        super(props)
    }

    componentDidMount() {
        this.setState({
            generatedBom: new Array<ProductDataResult>(), recommendedProducts: new Array<ProductDataResult>(),
            additionalProducts: new Array<ProductDataResult>
        })
    }

    handleClose = () => {
        this.setState(state => ({
            show: false
        }))
    }

    handleShow = () => {
        this.setState(state => ({
            show: true
        }))
    }

    displayExportPopUp() {

        this.setState(state => ({
            fileName: "ConstellationBoM.pdf"
        }))
        this.setState(state => ({
            show: true,
            isDivProjectDetailsVisible: true,
            isDivConfirmationVisible: false
        }))
    }

    createCableSelectionsImage = async () => {
        let element = document.getElementById('billOfMaterials')!,
            canvas = await html2canvas(element, { scale: 3 }),
            data = canvas.toDataURL('image/jpg'),
            link = document.createElement('a');

        return data;
    };

    createApplicationsTableImage = async () => {
        let element = document.getElementById('recommendedProducts')!,
            canvas = await html2canvas(element, { scale: 3 }),
            data = canvas.toDataURL('image/jpg'),
            link = document.createElement('a');

        return data;
    };

    createCableAttributesImage = async () => {
        let element = document.getElementById('additionalProducts')!,
            canvas = await html2canvas(element, { scale: 3 }),
            data = canvas.toDataURL('image/jpg'),
            link = document.createElement('a');

        return data;
    };

    styles = StyleSheet.create({
        page: {
            backgroundColor: '#fff',
            fontFamily: 'Helvetica',
            fontSize: 11,
            paddingTop: 30,
            paddingLeft: 30,
            paddingRight: 30,
            lineHeight: 1.5,
            flexDirection: 'column',
            paddingBottom: 50
        },
        logo: {
            height: '100px',
            width: '100px',
            marginBottom: -20,
            marginLeft: 230

        },
        pageNumber: {
            position: 'absolute',
            fontSize: 12,
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey',
        },
        left: {
            // width: '33%',//<- working alternative
            flexGrow: 0,
            flexShrink: 1,
            flexBasis: 200,
        },

        right: {
            padding: 5,
            // width: '66%', //<- working alternative
            flexShrink: 1,
            flexGrow: 2,
        },

        row: { display: "flex", flexDirection: "row", fontSize: 9, width: '100%' },
        column: {
            display: "flex", flexDirection: "column", width: '100%', paddingTop: 12,
            paddingBottom: 8
        },
        table: {
            width: '100%', marginBottom: 20
        },
        tableAdditionalProducts: {
            width: '100%', marginBottom: 20
        },
        tableRecommendedProducts: {
            width: '100%', marginBottom: 20
        },
        bold: {
            fontWeight: 'bold',
        },
        headerColumn1: {
            width: '20%', borderTop: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000', textAlign: 'left',
            paddingTop: '5px', paddingLeft: '3px', borderBottom: '1px solid #000000', fontFamily: 'Roboto-Medium'
        },
        headerColumn2: {
            width: '15%', borderTop: '1px solid #000000', borderRight: '1px solid #000000', textAlign: 'left', paddingTop: '5px',
            borderBottom: '1px solid #000000', fontWeight: 'bold', paddingLeft: '3px', fontFamily: 'Roboto-Medium'
        },
        headerColumn3: {
            width: '7%', borderTop: '1px solid #000000', borderRight: '1px solid #000000', textAlign: 'left', paddingTop: '5px',
            borderBottom: '1px solid #000000', fontWeight: 'bold', paddingLeft: '3px', fontFamily: 'Roboto-Medium'
        },
        headerColumn4: {
            width: '58%', borderTop: '1px solid #000000', borderRight: '1px solid #000000', textAlign: 'left', paddingTop: '5px',
            borderBottom: '1px solid #000000', fontWeight: 'bold', paddingLeft: '3px', fontFamily: 'Roboto-Medium'
        },
        column1: {
            width: '20%', borderLeft: '1px solid #000000', borderRight: '1px solid #000000', borderBottom: '1px solid #000000', textAlign: 'left',
            paddingTop: 2, paddingLeft: '3px'
        },
        column2: {
            width: '15%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000', textAlign: 'left', paddingTop: 2, paddingLeft: '3px'
        },
        column3: {
            width: '7%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000', textAlign: 'left', paddingTop: 2, paddingLeft: '3px'
        },
        column4: {
            width: '58%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000', textAlign: 'left', paddingTop: 2, paddingLeft: '3px'
        }
    });



    componentWillReceiveProps(props: any) {
        this.setState({ generatedBom: props.generatedBom, recommendedProducts: props.recommendedProducts, additionalProducts: props.additionalProducts })

        this.createCableSelectionsImage = async () => {
            let element = document.getElementById('billOfMaterials')!,
                canvas = await html2canvas(element, { scale: 3 }),
                data = canvas.toDataURL('image/jpg'),
                link = document.createElement('a');

            return data;
        };

    }

    render() {
        const { show, isDivProjectDetailsVisible,
            isDivConfirmationVisible, fileName, generatedBom, recommendedProducts, additionalProducts } = this.state

















        //first table some part  is getting lapped when data is more else perfect on small data

        const maxItemsPerPage = 10; // Maximum number of items per page

        const pdfDoc = (
            <Document>
                <Page size="A4" style={this.styles.page} wrap={true}>
                    <View style={this.styles.row}>
                        <View>
                            <Text style={{ fontSize: "15px", color: "#0039c1", fontFamily: "Rajdhani-Bold", marginTop: -15 }}>
                                Constellation Building Edge Infrastructure
                            </Text>
                        </View>
                        <View style={this.styles.column}>
                            <View style={this.styles.logo}>
                                <Image style={{ marginTop: -33 }} src={"CommScopeHeader.PNG"} />
                            </View>
                        </View>
                    </View>
                    <View style={{ marginBottom: 20, marginTop: -70 }}>
                        <Text style={{ fontSize: "6px", fontFamily: "Roboto-Light", marginTop: 10 }}>
                            DISCLAIMER: These calculations are provided for guidance purposes only. They
                            are provided as is and should not be relied upon without consultation with
                            and supervision of experienced personnel and a local CommScope
                            representative. CommScope makes no representations or warranties of any kind
                            related to these tools, whether express or implied, and specifically
                            disclaims and excludes any representation or warranty of merchantability,
                            quality, content, completeness, suitability, adequacy, accuracy,
                            noninfringement or fitness for a particular purpose and any representation
                            arising by usage of trade, course of dealing or course of performance.
                            CommScope is under no obligation to issue any upgrades, update
                            specifications or notify users of this tool that changes have been made. The
                            user of these tools assumes all risks associated with such use, and
                            CommScope hereby disclaims any and all liability for damages of any kind
                            resulting from such use.
                        </Text>
                    </View>
                    <View style={{ marginTop: 0, marginBottom: 15 }}>
                        <Text style={{ fontSize: "15px", color: "#0039c1", fontFamily: "Rajdhani-Bold" }}>
                            Constellation Bill of Materials
                        </Text>
                    </View>
                    <View style={this.styles.table} wrap={true}>
                        <View style={[this.styles.row, this.styles.bold]}>
                            <Text style={this.styles.headerColumn1} >Part Number</Text>
                            <Text style={this.styles.headerColumn2}>Length (meters)</Text>
                            <Text style={this.styles.headerColumn3}>Qty</Text>
                            <Text style={this.styles.headerColumn4}>Description</Text>
                        </View>
                        {generatedBom.map((bomProduct: ProductDataResult) => (
                            <View style={this.styles.row}>
                                <View style={this.styles.column1}>
                                    <View><Text style={{}}>{bomProduct.partNumber}</Text></View>
                                </View>
                                {bomProduct.length === 0 &&
                                    <View style={this.styles.column2}>
                                        <View><Text>-</Text></View>
                                    </View>
                                }
                                {bomProduct.length > 0 &&
                                    <View style={this.styles.column2}>
                                        <View><Text>{bomProduct.length.toString()}</Text></View>
                                    </View>
                                }
                                {bomProduct.quantity === 0 &&
                                    <View style={this.styles.column3}>
                                        <View><Text>-</Text></View>
                                    </View>
                                }
                                {bomProduct.quantity > 0 &&
                                    <View style={this.styles.column3}>
                                        <View><Text>{bomProduct.quantity.toString()}</Text></View>
                                    </View>
                                }
                                <View style={this.styles.column4}>
                                    <View><Text>{bomProduct.summary.toString()}</Text></View>
                                </View>
                            </View>
                        ))}
                    </View>

                    <View style={{ marginTop: 0, marginBottom: 15 }}>
                        <Text style={{ fontSize: "15px", color: "#0039c1", fontFamily: "Rajdhani-Bold" }}>
                            Recommended Products
                        </Text>
                    </View>

                    <View style={this.styles.tableRecommendedProducts} wrap={true}>
                        <View style={[this.styles.row, this.styles.bold]}>
                            <Text style={this.styles.headerColumn1}>Part Number</Text>
                            <Text style={this.styles.headerColumn2}>Length (meters)</Text>
                            <Text style={this.styles.headerColumn3}>Qty</Text>
                            <Text style={this.styles.headerColumn4}>Description</Text>
                        </View>
                        {recommendedProducts.map((bomProduct: ProductDataResult) => (
                            <View style={this.styles.row}>
                                <View style={this.styles.column1}>
                                    <View><Text style={{}}>{bomProduct.partNumber}</Text></View>
                                </View>
                                {bomProduct.length === 0 &&
                                    <View style={this.styles.column2}>
                                        <View><Text>-</Text></View>
                                    </View>
                                }
                                {bomProduct.length > 0 &&
                                    <View style={this.styles.column2}>
                                        <View><Text>{bomProduct.length.toString()}</Text></View>
                                    </View>
                                }
                                {bomProduct.quantity === 0 &&
                                    <View style={this.styles.column3}>
                                        <View><Text>-</Text></View>
                                    </View>
                                }
                                {bomProduct.quantity > 0 &&
                                    <View style={this.styles.column3}>
                                        <View><Text>{bomProduct.quantity.toString()}</Text></View>
                                    </View>
                                }
                                <View style={this.styles.column4}>
                                    <View><Text>{bomProduct.summary.toString()}</Text></View>
                                </View>
                            </View>
                        ))}
                    </View>
                    {additionalProducts !== undefined && additionalProducts.length > 0 &&
                        <View style={{ marginBottom: 15, marginTop: 0 }}>
                            <Text style={{ fontSize: "15px", color: "#0039c1", fontFamily: "Rajdhani-Bold" }}>
                                Additional Products
                            </Text>
                        </View>
                    }
                    {additionalProducts !== undefined && additionalProducts.length > 0 &&

                        <View style={this.styles.tableAdditionalProducts} wrap={true}>
                            <View style={[this.styles.row, this.styles.bold]}>
                                <Text style={this.styles.headerColumn1}>Part Number</Text>
                                <Text style={this.styles.headerColumn2}>Length (meters)</Text>
                                <Text style={this.styles.headerColumn3}>Qty</Text>
                                <Text style={this.styles.headerColumn4}>Description</Text>
                            </View>
                            {additionalProducts.map((bomProduct: ProductDataResult) => (
                                <View style={this.styles.row}>
                                    <View style={this.styles.column1}>
                                        <View><Text style={{}}>{bomProduct.partNumber}</Text></View>
                                    </View>
                                    {bomProduct.length === 0 &&
                                        <View style={this.styles.column2}>
                                            <View><Text>-</Text></View>
                                        </View>
                                    }
                                    {bomProduct.length > 0 &&
                                        <View style={this.styles.column2}>
                                            <View><Text>{bomProduct.length.toString()}</Text></View>
                                        </View>
                                    }
                                    {bomProduct.quantity === 0 &&
                                        <View style={this.styles.column3}>
                                            <View><Text>-</Text></View>
                                        </View>
                                    }
                                    {bomProduct.quantity > 0 &&
                                        <View style={this.styles.column3}>
                                            <View><Text>{bomProduct.quantity.toString()}</Text></View>
                                        </View>
                                    }
                                    <View style={this.styles.column4}>
                                        <View><Text>{bomProduct.summary.toString()}</Text></View>
                                    </View>
                                </View>
                            ))}
                        </View>
                    }
                    <View style={{ position: 'absolute' }} fixed>
                        <Text style={{ fontSize: "8px", fontFamily: "Roboto-Regular", marginTop: 800, width: 600, textAlign: 'center' }}>
                            &copy; CommScope, Inc. All rights reserved. All trademarks identified by &#174; or &#8482; are registered trademarks, respectively, of CommScope.
                        </Text>
                    </View>
                </Page>
                ){'}'}
            </Document>
        );



















        //const pdfDoc = (

        //    <Document >
        //        <Page size="A4" style={this.styles.page} wrap={true}>
        //            <View >
        //                <View style={this.styles.row}>
        //                    <View ><Text style={{
        //                        fontSize: "15px", color: "#0039c1", fontFamily: "Rajdhani-Bold", marginTop: -15
        //                    }}>
        //                        Constellation Building Egde Infrastructure
        //                    </Text ></View>
        //                    <View style={this.styles.column}>
        //                        <View style={this.styles.logo} >
        //                            <Image style={{ marginTop: -33 }} src={"CommScopeHeader.PNG"} />
        //                        </View>
        //                    </View>
        //                </View >
        //                <View style={{ marginBottom: 20, marginTop: -80 }}>

        //                    <Text style={{ fontSize: "6px", fontFamily: "Roboto-Light", marginTop: 10 }}>

        //                        DISCLAIMER: These calculations are provided for guidance purposes only. They
        //                        are provided as is and should not be relied upon without consultation with
        //                        and supervision of experienced personnel and a local CommScope
        //                        representative. CommScope makes no representations or warranties of any kind
        //                        related to these tools, whether express or implied, and specifically
        //                        disclaims and excludes any representation or warranty of merchantability,
        //                        quality, content, completeness, suitability, adequacy, accuracy,
        //                        noninfringement or fitness for a particular purpose and any representation
        //                        arising by usage of trade, course of dealing or course of performance.
        //                        CommScope is under no obligation to issue any upgrades, update
        //                        specifications or notify users of this tool that changes have been made. The
        //                        user of these tools assumes all risks associated with such use, and
        //                        CommScope hereby disclaims any and all liability for damages of any kind
        //                        resulting from such use.

        //                    </Text >

        //                </View>
        //                <View style={{ marginTop: 0, marginBottom: 15 }} ><Text style={{
        //                    fontSize: "15px", color: "#0039c1", fontFamily: "Rajdhani-Bold"
        //                }}>
        //                    Constellation Bill of Materials
        //                </Text ></View>
        //                <View>
        //                    <View style={this.styles.table} wrap={true}>
        //                        <View style={[this.styles.row, this.styles.bold]}>
        //                            <Text style={this.styles.headerColumn1}>Part Number</Text>
        //                            <Text style={this.styles.headerColumn2}>Length (meters)</Text>
        //                            <Text style={this.styles.headerColumn3}>Qty</Text>
        //                            <Text style={this.styles.headerColumn4}>Description</Text>
        //                        </View>

        //                        {
        //                            generatedBom.map((bomProduct: ProductDataResult) => (
        //                                <View style={this.styles.row}>
        //                                    <View style={this.styles.column1}>
        //                                        <View ><Text style={{}}>{bomProduct.partNumber}</Text></View>
        //                                    </View>
        //                                    {bomProduct.length === 0 &&
        //                                        <View style={this.styles.column2}>
        //                                            <View ><Text>-</Text></View>

        //                                        </View>
        //                                    }
        //                                    {bomProduct.length > 0 &&
        //                                        <View style={this.styles.column2}>
        //                                            <View ><Text>{bomProduct.length.toString()}</Text></View>

        //                                        </View>
        //                                    }
        //                                    {bomProduct.quantity === 0 &&
        //                                        <View style={this.styles.column2}>
        //                                            <View ><Text>-</Text></View>

        //                                        </View>
        //                                    }
        //                                    {bomProduct.quantity > 0 &&
        //                                        <View style={this.styles.column2}>
        //                                            <View ><Text>{bomProduct.quantity.toString()}</Text></View>

        //                                        </View>
        //                                    }
        //                                    <View style={this.styles.column4}>
        //                                        <View ><Text>{bomProduct.summary.toString()}</Text></View>

        //                                    </View>
        //                                </View>
        //                            ))}
        //                    </View >
        //                </View >

        //            </View>


        //            <View style={{ position: 'absolute' }} fixed >
        //                <Text style={{ fontSize: "8px", fontFamily: "Roboto-Regular", marginTop: 800, width: 600, textAlign: 'center' }}>

        //                    &copy; CommScope, Inc. All rights reserved. All trademarks identified by &#174; or &#8482; are registered trademarks, respectively, of CommScope.

        //                </Text >
        //            </View >
        //        </Page>
        //        <Page size="A4" style={this.styles.page} wrap={true}>
        //            <View>
        //                <View wrap={true} >
        //                    <View style={{ marginTop: 0, marginBottom: 12 }} ><Text style={{
        //                        fontSize: "15px", color: "#0039c1", fontFamily: "Rajdhani-Bold"
        //                    }}>
        //                        Recommended Products
        //                    </Text ></View>
        //                    <View>
        //                        <View style={this.styles.table} wrap={true}>
        //                            <View style={[this.styles.row, this.styles.bold]}>
        //                                <Text style={this.styles.headerColumn1}>Part Number</Text>
        //                                <Text style={this.styles.headerColumn2}>Length (meters)</Text>
        //                                <Text style={this.styles.headerColumn3}>Qty</Text>
        //                                <Text style={this.styles.headerColumn4}>Description</Text>
        //                            </View>

        //                            {
        //                                recommendedProducts.map((bomProduct: ProductDataResult) => (
        //                                    <View style={this.styles.row}>
        //                                        <View style={this.styles.column1}>
        //                                            <View ><Text style={{}}>{bomProduct.partNumber}</Text></View>
        //                                        </View>
        //                                        {bomProduct.length === 0 &&
        //                                            <View style={this.styles.column2}>
        //                                                <View ><Text>-</Text></View>

        //                                            </View>
        //                                        }
        //                                        {bomProduct.length > 0 &&
        //                                            <View style={this.styles.column2}>
        //                                                <View ><Text>{bomProduct.length.toString()}</Text></View>

        //                                            </View>
        //                                        }
        //                                        {bomProduct.quantity === 0 &&
        //                                            <View style={this.styles.column2}>
        //                                                <View ><Text>-</Text></View>

        //                                            </View>
        //                                        }
        //                                        {bomProduct.quantity > 0 &&
        //                                            <View style={this.styles.column2}>
        //                                                <View ><Text>{bomProduct.quantity.toString()}</Text></View>

        //                                            </View>
        //                                        }
        //                                        <View style={this.styles.column4}>
        //                                            <View ><Text>{bomProduct.summary.toString()}</Text></View>

        //                                        </View>
        //                                    </View>
        //                                ))}
        //                        </View >
        //                    </View >
        //                </View>
        //                {additionalProducts !== undefined && additionalProducts.length > 0 &&
        //                    <View style={{ marginTop:30 }}>
        //                        <View style={{ marginBottom: 15, marginTop: -350 }} ><Text style={{
        //                            fontSize: "15px", color: "#0039c1", fontFamily: "Rajdhani-Bold"
        //                        }}>
        //                            Additional Products
        //                        </Text ></View>
        //                        <View>
        //                            <View style={this.styles.table} wrap={true}>
        //                                <View style={[this.styles.row, this.styles.bold]}>
        //                                    <Text style={this.styles.headerColumn1}>Part Number</Text>
        //                                    <Text style={this.styles.headerColumn2}>Length (meters)</Text>
        //                                    <Text style={this.styles.headerColumn3}>Qty</Text>
        //                                    <Text style={this.styles.headerColumn4}>Description</Text>
        //                                </View>

        //                                {
        //                                    additionalProducts.map((bomProduct: ProductDataResult) => (
        //                                        <View style={this.styles.row}>
        //                                            <View style={this.styles.column1}>
        //                                                <View ><Text style={{}}>{bomProduct.partNumber}</Text></View>
        //                                            </View>
        //                                            {bomProduct.length === 0 &&
        //                                                <View style={this.styles.column2}>
        //                                                    <View ><Text>-</Text></View>

        //                                                </View>
        //                                            }
        //                                            {bomProduct.length > 0 &&
        //                                                <View style={this.styles.column2}>
        //                                                    <View ><Text>{bomProduct.length.toString()}</Text></View>

        //                                                </View>
        //                                            }
        //                                            {bomProduct.quantity === 0 &&
        //                                                <View style={this.styles.column2}>
        //                                                    <View ><Text>-</Text></View>

        //                                                </View>
        //                                            }
        //                                            {bomProduct.quantity > 0 &&
        //                                                <View style={this.styles.column2}>
        //                                                    <View ><Text>{bomProduct.quantity.toString()}</Text></View>

        //                                                </View>
        //                                            }
        //                                            <View style={this.styles.column4}>
        //                                                <View ><Text>{bomProduct.summary.toString()}</Text></View>

        //                                            </View>
        //                                        </View>
        //                                    ))}
        //                            </View >
        //                        </View >
        //                    </View>
        //                }
        //            </View>

        //            <View style={{ position: 'absolute' }} fixed >
        //                <Text style={{ fontSize: "8px", fontFamily: "Roboto-Regular", marginTop: 800, width: 600, textAlign: 'center' }}>

        //                    &copy; CommScope, Inc. All rights reserved. All trademarks identified by &#174; or &#8482; are registered trademarks, respectively, of CommScope.

        //                </Text >
        //            </View >
        //        </Page>
        //    </Document >
        //);

        return (
            <>
                <div className='download-link ml-4'>
                    <PDFDownloadLink
                        document={pdfDoc}
                        fileName='ConstellationBoM' className="btn btn-primary">
                        Save as PDF
                    </PDFDownloadLink>
                </div>
            </>
        );
    }
}

export default DownloadPDFButton;